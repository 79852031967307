import { Divider, Flex, Title } from '@mantine/core'
import React from 'react'

const TitleDivider = ({ titleText, epgText }) => {
  return (
    <>
      <Flex gap={0} align="center">
        <Title size={18} order={2} align="left" style={{ fontWeight: 'lighter' }}>
          {titleText}
        </Title>
        <Title color="primary" ml={10} size={10}>
          {epgText}
        </Title>
      </Flex>
      <Divider
        size="sm"
        sx={{ borderColor: '#7b39b6', marginBottom: '10px' }}
        w={40}
        mb={15}
        mt={3}
      />
    </>
  )
}

export default TitleDivider
