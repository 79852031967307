import { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const PlatformsContext = createContext()

export function PlatformsProvider({ children }) {
  // Inicializamos el estado con un valor por defecto vacío
  const [selectedPlatforms, setSelectedPlatformsState] = useState([])

  // Cargamos las plataformas guardadas al montar el componente
  useEffect(() => {
    const savedPlatforms = localStorage.getItem('selectedPlatforms')
    if (savedPlatforms) {
      setSelectedPlatformsState(JSON.parse(savedPlatforms))
    }
  }, [])

  // Wrapper para setSelectedPlatforms que también guarda en localStorage
  const setSelectedPlatforms = (platforms) => {
    setSelectedPlatformsState(platforms)
    localStorage.setItem('selectedPlatforms', JSON.stringify(platforms))
  }

  return (
    <PlatformsContext.Provider value={{ selectedPlatforms, setSelectedPlatforms }}>
      {children}
    </PlatformsContext.Provider>
  )
}

PlatformsProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export function usePlatforms() {
  const context = useContext(PlatformsContext)
  if (!context) {
    throw new Error('usePlatforms must be used within a PlatformsProvider')
  }
  return context
}
