import { Box, Button, Card, Flex, Group, Stack, Text, Loader } from '@mantine/core'
import { useTranslations } from 'next-intl'
import Head from 'next/head'
import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import TitleDivider from '../components/divider'
import ImageFallback from '../components/image_fallback'
import Layout from '../components/layout'
import styles from '../styles/Home.module.css'
import { searchAPI_v2 } from '../utils/axios'
import countries from '../utils/countries.json'
import InfiniteScroll from 'react-infinite-scroll-component'
import Image from 'next/image'
import { Carousel } from '@mantine/carousel'
import CountryInput from '../components/inputs/country_input'
import { usePlatforms } from '../context/platforms'

const fetcher = (url) => fetch(url).then((res) => res.json())

const sortPlatformsBySelected = (platforms, selectedIds) => {
  // Crear una copia del array para no mutar el original
  const sortedPlatforms = [...platforms]

  // Ordenar basado en selectedPlatforms
  return sortedPlatforms.sort((a, b) => {
    const aIndex = selectedIds.indexOf(a.id)
    const bIndex = selectedIds.indexOf(b.id)

    // Si ambos están en selectedPlatforms, mantener el orden de selectedPlatforms
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex
    }

    // Si solo uno está en selectedPlatforms, ponerlo primero
    if (aIndex !== -1) return -1
    if (bIndex !== -1) return 1

    // Si ninguno está en selectedPlatforms, mantener el orden original
    return 0
  })
}

export default function Home({
  title,
  initialPlatformHits,
  countryHits,
  initialAllPlatforms = []
}) {
  const { locale } = useRouter()
  const countryCode = locale.split('-')[1]?.toLowerCase()
  const { selectedPlatforms } = usePlatforms()

  console.log('selectedPlatforms', selectedPlatforms)
  console.log('initialAllPlatforms', initialAllPlatforms)

  const [platformHits, setPlatformHits] = useState(initialPlatformHits)
  const [allPlatforms, setAllPlatforms] = useState(initialAllPlatforms)
  const [hasMore, setHasMore] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [visiblePlatforms, setVisiblePlatforms] = useState([])
  const [contentFilter, setContentFilter] = useState('all')
  const [filteredCountryHits, setFilteredCountryHits] = useState(countryHits)

  const handleCountryChange = (newCountry) => {
    if (!newCountry) return

    const spanishCountries = ['ar', 'mx', 'es', 'cl', 'co', 'pe']

    const defaultLanguage = spanishCountries.includes(newCountry.toLowerCase())
      ? 'es'
      : 'en'
    const newLocale = `${defaultLanguage}-${newCountry.toUpperCase()}`

    // Construimos la nueva URL y hacemos el reload directamente
    const newPath = `/${newLocale}`
    window.location.href = newPath
  }

  useEffect(() => {
    const filtered = countryHits.filter((item) => {
      if (contentFilter === 'all') return true
      if (contentFilter === 'serie')
        return item.content_type.toLowerCase() === 'series'
      return item.content_type.toLowerCase() === contentFilter
    })
    setFilteredCountryHits(filtered)
  }, [countryHits, contentFilter])

  useEffect(() => {
    // Filtrar plataformas duplicadas usando Set
    const uniquePlatforms = [...allPlatforms].filter(
      (platform, index, self) =>
        index === self.findIndex((p) => p.platform === platform.platform)
    )

    // Ordenar plataformas por cantidad de contenido filtrado
    const sorted = uniquePlatforms.sort((a, b) => {
      const aContent =
        platformHits[a.platform]?.data.filter((item) => {
          if (contentFilter === 'all') return true
          if (contentFilter === 'serie')
            return item.content_type.toLowerCase() === 'series'
          return item.content_type.toLowerCase() === contentFilter
        }).length || 0

      const bContent =
        platformHits[b.platform]?.data.filter((item) => {
          if (contentFilter === 'all') return true
          if (contentFilter === 'serie')
            return item.content_type.toLowerCase() === 'series'
          return item.content_type.toLowerCase() === contentFilter
        }).length || 0

      return bContent - aContent
    })

    console.log('------- Control de Plataformas -------')
    console.log('Total plataformas disponibles:', initialAllPlatforms.length)
    console.log('Total plataformas cargadas:', Object.keys(platformHits).length)
    console.log('Plataformas ordenadas:', sorted.length)
    console.log('Filtro actual:', contentFilter)
    console.log('------------------------------------')

    setVisiblePlatforms(sorted)
  }, [
    allPlatforms,
    platformHits,
    contentFilter,
    initialAllPlatforms,
    selectedPlatforms
  ])

  const loadMore = async () => {
    if (isLoadingMore) return
    setIsLoadingMore(true)

    try {
      // Obtener el siguiente lote de plataformas desde initialAllPlatforms
      const nextPlatforms = initialAllPlatforms.slice(
        Object.keys(platformHits).length,
        Object.keys(platformHits).length + 50 // Aumentamos a 20 plataformas por carga
      )

      // Si no hay más plataformas para cargar, terminamos
      if (!nextPlatforms.length) {
        setHasMore(false)
        setIsLoadingMore(false)
        return
      }

      // Cargar contenido para las siguientes plataformas
      const newPlatformParams = nextPlatforms.map((platform) => ({
        country: countryCode,
        type: ['movie', 'series'],
        limit: 10,
        platform: platform.id,
        logo: platform.logo,
        language: locale.split('-')[0]
      }))

      const results = await Promise.all(
        newPlatformParams.map(async (params) => {
          const [moviesData, seriesData] = await Promise.all([
            fetcher(
              `/api/api_ext/content/hits?country=${params.country}&type=movie&platform=${params.platform}&limit=${params.limit}`
            ),
            fetcher(
              `/api/api_ext/content/hits?country=${params.country}&type=series&platform=${params.platform}&limit=${params.limit}`
            )
          ])

          const allContent = [...moviesData.data, ...seriesData.data]
          const uniqueContent = Array.from(
            new Map(allContent.map((item) => [item.uid, item])).values()
          )

          return uniqueContent
            .map((item) => ({
              title: item.translation?.title || item.title,
              poster: item.poster,
              backdrop: item.backdrop,
              genre: item.genres[0] || '',
              year: item.year,
              uid: item.uid,
              slug: item.slug,
              content_type: item.content_type,
              hits: item.hits
            }))
            .sort((a, b) => b.hits - a.hits)
        })
      )

      const newHits = nextPlatforms.reduce((acc, platform, index) => {
        acc[platform.platform] = {
          id: platform.id,
          data: results[index] || [],
          logo: platform.logo
        }
        return acc
      }, {})

      // Actualizar el estado
      setPlatformHits((prev) => ({ ...prev, ...newHits }))
      setHasMore(
        Object.keys(platformHits).length + nextPlatforms.length <
          initialAllPlatforms.length
      )
    } catch (error) {
      console.error('Error loading more platforms:', error)
      setHasMore(false)
    } finally {
      setIsLoadingMore(false)
    }
  }

  const t = useTranslations('Index')
  const t_genres = useTranslations('Genres')

  const generateContentString = (contentType, genre, year) => {
    let contentString = contentType
    if (genre) contentString += ` | ${genre}`
    if (year) contentString += ` | ${year}`
    return contentString
  }

  const country =
    countries.find((c) => c.code === countryCode?.toUpperCase())?.name || countryCode

  return (
    <>
      <Head>
        <title>{title || t('pageTitle')}</title>
        <meta name="description" content={t('pageDescription')} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content={title || t('pageTitle')} />
        <meta property="og:description" content={t('pageDescription')} />
        <meta
          property="og:url"
          content={`https://www.streamdiscover.com/${locale}`}
        />
        <meta property="og:image" content="/path/to/your/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title || t('pageTitle')} />
        <meta name="twitter:description" content={t('pageDescription')} />
        <meta name="twitter:image" content="/path/to/your/image.jpg" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main
        className={styles.main}
        style={{
          width: '80vw',
          maxWidth: '1366px',
          margin: '0 auto'
        }}
      >
        <Stack
          w="100%"
          spacing={0}
          align="start"
          mt={10}
          sx={(theme) => ({
            width: '80vw',
            maxWidth: '100%',
            [theme.fn.smallerThan('md')]: { width: '85vw' },
            [theme.fn.smallerThan('sm')]: { width: '90vw' }
          })}
        >
          <Flex justify="space-between" align="center" w="100%" mb={20}>
            <Box sx={{ width: '100%' }}>
              <TitleDivider
                titleText={
                  <Flex align="center" gap={8}>
                    <Text component="span">{t('trendingCarouselTitle')}</Text>
                    <CountryInput
                      withLabel={false}
                      onChange={handleCountryChange}
                      value={countryCode}
                      size="xs"
                      style={{ maxWidth: '150px' }}
                      icon={null}
                      uppercase={true}
                    />
                  </Flex>
                }
              />
            </Box>
          </Flex>

          <Flex
            justify="space-between"
            align="center"
            w="100%"
            mt={10}
            mb={20}
            direction={{ base: 'column', sm: 'row' }}
            gap={{ base: 'md', sm: 0 }}
          >
            <Box w={{ base: '100%', sm: '60%' }} pos="relative">
              <Carousel
                {...{
                  slideSize: { base: '40px', sm: '50px' },
                  height: { base: 40, sm: 50 },
                  slideGap: 'md',
                  align: 'start',
                  slidesToScroll: { base: 3, sm: 5 },
                  withControls: initialAllPlatforms.length > 5,
                  dragFree: true,
                  containScroll: 'trimSnaps',
                  styles: {
                    control: {
                      backgroundColor: '#0E1922',
                      border: 'none',
                      color: 'rgba(123,57,182,1)',
                      '&:hover': {
                        backgroundColor: '#1a2733',
                        color: 'rgba(123,57,182,0.8)'
                      },
                      width: 30,
                      minWidth: 30,
                      height: '100%',
                      borderRadius: 4,
                      boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                      transition: 'all 0.2s ease',
                      position: 'absolute',
                      top: 0,
                      transform: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      '&[data-inactive]': {
                        opacity: 0,
                        cursor: 'default'
                      },
                      '&:first-of-type': {
                        left: -40,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0
                      },
                      '&:last-of-type': {
                        right: -40,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0
                      }
                    },
                    viewport: {
                      margin: '0'
                    },
                    container: {
                      margin: '0'
                    }
                  }
                }}
              >
                {sortPlatformsBySelected(initialAllPlatforms, selectedPlatforms).map(
                  (platform) => (
                    <Carousel.Slide key={platform.platform}>
                      <Link
                        href={`/${locale}/hits/${countryCode}/${
                          platform.id
                        }-${platform.platform.replace(/\s+/g, '').toLowerCase()}`}
                      >
                        <Box
                          sx={{
                            width: 50,
                            height: 50,
                            borderRadius: 8,
                            overflow: 'hidden',
                            transition: 'all 0.2s ease',
                            '&:hover': {
                              transform: 'scale(1.05)',
                              boxShadow: '0 4px 8px rgba(123,57,182,0.2)'
                            },
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            backgroundColor: '#0E1922',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative'
                          }}
                        >
                          <ImageFallback
                            src={`https://wsrv.nl/?url=${platform.logo}&w=100&output=webp&q=100&il=1`}
                            fallbackSrc="/noimage.png"
                            alt={`${platform.platform} logo`}
                            style={{
                              objectFit: 'contain',
                              maxWidth: '100%',
                              maxHeight: '100%'
                            }}
                            width={40}
                            height={40}
                          />
                        </Box>
                      </Link>
                    </Carousel.Slide>
                  )
                )}
              </Carousel>
            </Box>

            <Group
              spacing={0}
              sx={{
                flexShrink: 0,
                width: '30%',
                display: 'flex',
                justifyContent: 'center',
                '@media (max-width: 768px)': { width: '100%' }
              }}
            >
              {['all', 'movie', 'serie'].map((filter, index) => (
                <Button
                  key={filter}
                  variant="unstyled"
                  size={{ base: 'xs', sm: 'sm' }}
                  onClick={() => setContentFilter(filter)}
                  className={`${styles.button} ${
                    contentFilter === filter
                      ? styles.buttonActive
                      : styles.buttonInactive
                  }`}
                  sx={{
                    boxShadow: 'inset 0px -3px 0px -1px #7b39b6',
                    width: '80px',
                    flexShrink: 0,
                    fontSize: { base: '12px', sm: '14px' },
                    borderRadius: ['4px 0 0 4px', '0', '0 4px 4px 0'][index]
                  }}
                >
                  {t(filter === 'all' ? 'all' : filter + 's')}
                </Button>
              ))}
            </Group>
          </Flex>

          <InfiniteScroll
            dataLength={allPlatforms.length}
            next={loadMore}
            hasMore={hasMore}
            loader={
              <Flex justify="center" align="center" w="100%" my="xl">
                <Loader color="rgba(123,57,182,1)" size="md" variant="bars" />
              </Flex>
            }
          >
            <Group
              position="apart"
              w="100%"
              my={24}
              spacing={0}
              sx={(theme) => ({
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px',
                justifyContent: 'flex-start',
                '& > *': {
                  flex: '0 0 calc(25% - 12px)',
                  maxWidth: 'calc(25% - 12px)',
                  minWidth: '250px'
                },
                [theme.fn.smallerThan(1400)]: {
                  '& > *': {
                    flex: '0 0 calc(33.333% - 11px)',
                    maxWidth: 'calc(33.333% - 11px)'
                  }
                },
                [theme.fn.smallerThan(1100)]: {
                  '& > *': {
                    flex: '0 0 calc(50% - 8px)',
                    maxWidth: 'calc(50% - 8px)'
                  }
                },
                [theme.fn.smallerThan(680)]: {
                  '& > *': {
                    flex: '0 0 100%',
                    maxWidth: '100%'
                  }
                }
              })}
            >
              <Box
                sx={{
                  width: '100%',
                  marginBottom: '32px'
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#0E1922',
                    padding: '12px',
                    borderRadius: '4px',
                    marginBottom: '8px',
                    boxShadow: '0px -3px 0px 0px rgba(123,57,182,1) inset',
                    textAlign: 'center'
                  }}
                >
                  <Text
                    size="sm"
                    tt="uppercase"
                    fw={500}
                    sx={{
                      color: 'rgba(123,57,182,1)',
                      fontSize: '12px',
                      letterSpacing: '0.5px'
                    }}
                  >
                    {t('topSeriesAndMovies')}
                  </Text>
                  <Link
                    href={`/${locale}/hits/${countryCode}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Text
                      size="xl"
                      tt="uppercase"
                      fw={200}
                      color="white"
                      sx={{
                        fontSize: '20px',
                        marginTop: '4px',
                        letterSpacing: '0.5px',
                        position: 'relative',
                        display: 'inline-block',
                        paddingBottom: '4px',
                        cursor: 'pointer',
                        '&:after': {
                          content: '""',
                          position: 'absolute',
                          width: '0',
                          height: '2px',
                          bottom: '0',
                          left: '50%',
                          top: '80%',
                          backgroundColor: '#7B39B6',
                          transition: 'all 0.3s ease',
                          transform: 'translateX(-50%)'
                        },
                        '&:hover:after': {
                          width: '100%'
                        }
                      }}
                    >
                      {country}
                    </Text>
                  </Link>
                </Box>

                <Card sx={{ overflow: 'visible', maxWidth: '100%' }} p={0}>
                  <Card.Section
                    sx={{
                      width: '100%',
                      margin: 0,
                      padding: 0,
                      height: '170px',
                      position: 'relative'
                    }}
                  >
                    <ImageFallback
                      src={
                        filteredCountryHits[0]?.backdrop
                          ? `https://wsrv.nl/?url=${filteredCountryHits[0].backdrop}&w=600&output=webp&q=100&il=1`
                          : '/noimage.png'
                      }
                      fallbackSrc="/noimage.png"
                      style={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        borderRadius: '4px'
                      }}
                      alt="movie poster"
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0px -3px 0px 0px rgba(123,57,182,1) inset',
                        borderRadius: '4px'
                      }}
                    >
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          padding: '5px',
                          justifyContent: 'flex-end',
                          textAlign: 'right'
                        }}
                      >
                        <Text
                          color="white"
                          tt="uppercase"
                          fw={700}
                          size={12}
                          sx={{
                            display: 'block'
                          }}
                        >
                          {filteredCountryHits[0]?.title
                            ? filteredCountryHits[0].title.length > 30
                              ? filteredCountryHits[0].title.substring(0, 30) + '...'
                              : filteredCountryHits[0].title
                            : '-'}
                        </Text>
                      </Box>
                      {filteredCountryHits[0]?.content_type?.toLowerCase() ===
                        'series' && (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 10,
                            padding: '5px',
                            backgroundColor: 'rgba(123,57,182,1)',
                            borderRadius: '0 0 5px 5px',
                            display: 'flex',
                            placeContent: 'center'
                          }}
                          w={21}
                          h={19}
                        >
                          <Text color="white" size={10} fw={700} p={0}>
                            TV
                          </Text>
                        </Box>
                      )}
                      <Box
                        sx={{
                          position: 'absolute',
                          bottom: -30,
                          right: 0,
                          padding: '5px'
                        }}
                      >
                        <Link
                          href={`/${locale}/hits/${countryCode}`}
                          style={{ textDecoration: 'none' }}
                        >
                          <div
                            style={{
                              width: 63,
                              height: 63,
                              borderRadius: '8px',
                              overflow: 'hidden',
                              backgroundColor: '#0E1922',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}
                          >
                            <Image
                              src={`https://kapowaz.github.io/square-flags/flags/${countryCode.toLowerCase()}.svg`}
                              width={130}
                              height={65}
                              alt={`${country} flag`}
                              style={{
                                objectFit: 'contain'
                              }}
                            />
                          </div>
                        </Link>
                      </Box>
                    </Box>
                  </Card.Section>
                  <Stack p="md">
                    {filteredCountryHits.slice(0, 5).map((hit, index) => (
                      <Link
                        key={hit.uid}
                        href={`/${hit.content_type.toLowerCase()}/${
                          hit.slug || hit.uid
                        }`}
                        passHref
                        prefetch={false}
                      >
                        <Flex align="center" sx={{ cursor: 'pointer' }}>
                          <Group
                            spacing={0}
                            sx={{ minWidth: '30%' }}
                            align="baseline"
                          >
                            <Text
                              fw={800}
                              color="rgba(123,57,182,1)"
                              size="35px"
                              sx={{
                                lineHeight: 0.9,
                                marginLeft: index === 0 ? '4px' : '0'
                              }}
                            >
                              {index + 1}
                            </Text>
                            <div
                              style={{
                                width: '45px',
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                            >
                              <ImageFallback
                                src={`https://wsrv.nl/?url=${hit.poster}&w=140&output=webp&q=100&il=1`}
                                fallbackSrc="/poster-placeholder-4.png"
                                width={45}
                                height={67}
                                alt="poster"
                                style={{
                                  borderRadius: '4px',
                                  ...(hit.poster
                                    ? { objectFit: 'cover' }
                                    : { objectFit: 'contain' })
                                }}
                                priority={false}
                              />
                            </div>
                          </Group>
                          <Stack spacing={0}>
                            <Text
                              color="white"
                              tt="uppercase"
                              fw={400}
                              size={14}
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'block',
                                maxWidth: '180px'
                              }}
                            >
                              {hit.title}
                            </Text>
                            <Text
                              color="#7B39B6"
                              tt="uppercase"
                              fw="bolder"
                              size={10}
                              sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: 'block',
                                maxWidth: '180px'
                              }}
                            >
                              {generateContentString(
                                t(hit.content_type),
                                t_genres(hit.genre),
                                hit.year
                              )}
                            </Text>
                          </Stack>
                        </Flex>
                      </Link>
                    ))}
                    {filteredCountryHits.slice(5, 10).map((hit, index) => (
                      <Link
                        href={`/${hit.content_type.toLowerCase()}/${
                          hit.slug || hit.uid
                        }`}
                        passHref
                        key={hit.uid}
                        prefetch={false}
                      >
                        <Flex
                          align="center"
                          sx={{
                            cursor: 'pointer',
                            marginTop: '-8px'
                          }}
                        >
                          <Group
                            spacing={0}
                            sx={{ minWidth: '30%' }}
                            align="baseline"
                          >
                            <Text
                              fw={800}
                              color="rgba(123,57,182,1)"
                              size="20px"
                              sx={{
                                lineHeight: 0.9,
                                marginLeft: '4px',
                                width: '20px',
                                textAlign: 'left',
                                ...(index === 4 && {
                                  marginLeft: '-3px'
                                })
                              }}
                            >
                              {index + 6}
                            </Text>
                          </Group>
                          <Text
                            color="white"
                            tt="uppercase"
                            fw={400}
                            size={14}
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: 'block',
                              maxWidth: '180px',
                              marginLeft: '-35px'
                            }}
                          >
                            {hit.title}
                          </Text>
                        </Flex>
                      </Link>
                    ))}
                    <Button
                      mt="xs"
                      size="xs"
                      fullWidth
                      component={Link}
                      href={`/${locale}/hits/${countryCode}`}
                      sx={{
                        backgroundColor: '#7B39B6',
                        color: 'white',
                        fontSize: '10px',
                        fontWeight: 'bold',
                        height: 'auto',
                        padding: '2px',
                        '&:hover': {
                          backgroundColor: '#6a2ca6'
                        }
                      }}
                    >
                      <Stack spacing={2} align="center">
                        <Text>{t('seeMore')}</Text>
                        <Text>{country.toUpperCase()}</Text>
                      </Stack>
                    </Button>
                  </Stack>
                </Card>
              </Box>

              {visiblePlatforms.map((platform) => {
                const platformData = platformHits[platform.platform]
                if (!platformData?.data?.length) return null

                const filteredData = platformData.data.filter((item) => {
                  if (contentFilter === 'all') return true
                  if (contentFilter === 'serie')
                    return item.content_type.toLowerCase() === 'series'
                  return item.content_type.toLowerCase() === contentFilter
                })

                if (!filteredData?.length) return null

                return (
                  <Box
                    key={platform.platform}
                    sx={{
                      width: '100%',
                      marginBottom: '32px'
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: '#0E1922',
                        padding: '12px',
                        borderRadius: '4px',
                        marginBottom: '8px',
                        boxShadow: '0px -3px 0px 0px rgba(123,57,182,1) inset',
                        textAlign: 'center'
                      }}
                    >
                      <Text
                        size="sm"
                        tt="uppercase"
                        fw={500}
                        sx={{
                          color: 'rgba(123,57,182,1)',
                          fontSize: '12px',
                          letterSpacing: '0.5px'
                        }}
                      >
                        {t('topSeriesAndMovies')}
                      </Text>
                      <Link
                        href={`/${locale}/hits/${countryCode}/${
                          platformData.id
                        }-${platform.platform.replace(/\s+/g, '').toLowerCase()}`}
                        style={{ textDecoration: 'none' }}
                      >
                        <Text
                          size="xl"
                          tt="uppercase"
                          fw={200}
                          color="white"
                          sx={{
                            fontSize: '20px',
                            marginTop: '4px',
                            letterSpacing: '0.5px',
                            position: 'relative',
                            display: 'inline-block',
                            paddingBottom: '4px',
                            cursor: 'pointer',
                            '&:after': {
                              content: '""',
                              position: 'absolute',
                              width: '0',
                              height: '2px',
                              bottom: '0',
                              left: '50%',
                              top: '80%',
                              backgroundColor: '#7B39B6',
                              transition: 'all 0.3s ease',
                              transform: 'translateX(-50%)'
                            },
                            '&:hover:after': {
                              width: '100%'
                            }
                          }}
                        >
                          {platform.platform}
                        </Text>
                      </Link>
                    </Box>

                    <Card
                      sx={{
                        overflow: 'visible',
                        maxWidth: '100%'
                      }}
                      p={0}
                    >
                      <Card.Section
                        sx={{
                          width: '100%',
                          margin: 0,
                          padding: 0,
                          height: '170px',
                          position: 'relative'
                        }}
                      >
                        <ImageFallback
                          src={
                            filteredData[0]?.backdrop
                              ? `https://wsrv.nl/?url=${filteredData[0].backdrop}&w=600&output=webp&q=100&il=1`
                              : '/noimage.png'
                          }
                          fallbackSrc="/noimage.png"
                          style={{
                            objectFit: 'cover',
                            width: '100%',
                            height: '100%',
                            borderRadius: '4px'
                          }}
                          alt="movie poster"
                        />
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: '0px -3px 0px 0px rgba(123,57,182,1) inset',
                            borderRadius: '4px'
                          }}
                        >
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              padding: '5px',
                              justifyContent: 'flex-end',
                              textAlign: 'right'
                            }}
                          >
                            <Text
                              color="white"
                              tt="uppercase"
                              fw={700}
                              size={12}
                              sx={{
                                display: 'block'
                              }}
                            >
                              {filteredData[0]?.title
                                ? filteredData[0].title.length > 30
                                  ? filteredData[0].title.substring(0, 30) + '...'
                                  : filteredData[0].title
                                : '-'}
                            </Text>
                          </Box>
                          {filteredData[0]?.content_type?.toLowerCase() ===
                            'series' && (
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 0,
                                left: 10,
                                padding: '5px',
                                backgroundColor: 'rgba(123,57,182,1)',
                                borderRadius: '0 0 5px 5px',
                                display: 'flex',
                                placeContent: 'center'
                              }}
                              w={21}
                              h={19}
                            >
                              <Text color="white" size={10} fw={700} p={0}>
                                TV
                              </Text>
                            </Box>
                          )}
                          <Box
                            sx={{
                              position: 'absolute',
                              bottom: -30,
                              right: 0,
                              padding: '5px'
                            }}
                          >
                            <Link
                              href={`/${locale}/hits/${countryCode}/${
                                platformData.id
                              }-${platform.platform
                                .replace(/\s+/g, '')
                                .toLowerCase()}`}
                              style={{ textDecoration: 'none' }}
                            >
                              <div
                                style={{
                                  width: platform.platform === 'disney' ? 58 : 63,
                                  height: platform.platform === 'disney' ? 58 : 63,
                                  borderRadius: '8px',
                                  overflow: 'hidden',
                                  cursor: 'pointer',
                                  transition: 'transform 0.2s',
                                  '&:hover': {
                                    transform: 'scale(1.1)'
                                  }
                                }}
                              >
                                <ImageFallback
                                  src={`https://wsrv.nl/?url=${platformData.logo}&w=200&output=webp&q=100&il=1`}
                                  fallbackSrc="/noimage.png"
                                  width={platform.platform === 'disney' ? 58 : 63}
                                  height={platform.platform === 'disney' ? 58 : 63}
                                  alt="logo"
                                  style={{
                                    objectFit: 'cover'
                                  }}
                                />
                              </div>
                            </Link>
                          </Box>
                        </Box>
                      </Card.Section>
                      <Stack
                        p="md"
                        sx={{
                          maxWidth: '100%',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden'
                        }}
                      >
                        {Array(5)
                          .fill(null)
                          .map((_, index) =>
                            filteredData[index] ? (
                              <Link
                                key={filteredData[index].uid}
                                href={`/${filteredData[
                                  index
                                ].content_type.toLowerCase()}/${
                                  filteredData[index].slug || filteredData[index].uid
                                }`}
                                passHref
                                prefetch={false}
                              >
                                <Flex align="center" sx={{ cursor: 'pointer' }}>
                                  <Group
                                    spacing={0}
                                    sx={{ minWidth: '30%' }}
                                    align="baseline"
                                  >
                                    <Text
                                      fw={800}
                                      color="rgba(123,57,182,1)"
                                      size="35px"
                                      sx={{
                                        lineHeight: 0.9,
                                        marginLeft: index === 0 ? '4px' : '0'
                                      }}
                                    >
                                      {index + 1}
                                    </Text>
                                    <div
                                      style={{
                                        width: '45px',
                                        display: 'flex',
                                        justifyContent: 'center'
                                      }}
                                    >
                                      <ImageFallback
                                        src={`https://wsrv.nl/?url=${filteredData[index].poster}&w=140&output=webp&q=100&il=1`}
                                        fallbackSrc="/poster-placeholder-4.png"
                                        width={45}
                                        height={67}
                                        alt="poster"
                                        style={{
                                          borderRadius: '4px',
                                          ...(filteredData[index].poster
                                            ? {
                                                objectFit: 'cover'
                                              }
                                            : {
                                                objectFit: 'contain'
                                              })
                                        }}
                                        priority={false}
                                      />
                                    </div>
                                  </Group>
                                  <Stack spacing={0}>
                                    <Text
                                      color="white"
                                      tt="uppercase"
                                      fw={400}
                                      size={14}
                                      sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'block',
                                        maxWidth: '180px'
                                      }}
                                    >
                                      {filteredData[index].title}
                                    </Text>
                                    <Text
                                      color="#7B39B6"
                                      tt="uppercase"
                                      fw="bolder"
                                      size={10}
                                      sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        display: 'block',
                                        maxWidth: '180px'
                                      }}
                                    >
                                      {generateContentString(
                                        t(filteredData[index].content_type),
                                        t_genres(filteredData[index].genre),
                                        filteredData[index].year
                                      )}
                                    </Text>
                                  </Stack>
                                </Flex>
                              </Link>
                            ) : (
                              <Flex key={`empty-${index}`} align="center">
                                <Group
                                  spacing={0}
                                  sx={{ minWidth: '30%' }}
                                  align="baseline"
                                >
                                  <Text
                                    fw={800}
                                    color="rgba(123,57,182,1)"
                                    size="35px"
                                    sx={{ lineHeight: 0.9 }}
                                  >
                                    {index + 1}
                                  </Text>
                                  <div
                                    style={{
                                      width: '45px',
                                      display: 'flex',
                                      justifyContent: 'center'
                                    }}
                                  >
                                    <ImageFallback
                                      src="/poster-placeholder-4.png"
                                      width={45}
                                      height={67}
                                      alt="empty poster"
                                      style={{
                                        borderRadius: '4px',
                                        objectFit: 'contain',
                                        opacity: 0.3
                                      }}
                                    />
                                  </div>
                                </Group>
                                <Stack spacing={0}>
                                  <Text
                                    color="white"
                                    tt="uppercase"
                                    fw={400}
                                    size={14}
                                  >
                                    -
                                  </Text>
                                  <Text
                                    color="#7B39B6"
                                    tt="uppercase"
                                    fw="bolder"
                                    size={10}
                                  >
                                    -
                                  </Text>
                                </Stack>
                              </Flex>
                            )
                          )}

                        {Array(5)
                          .fill(null)
                          .map((_, index) =>
                            filteredData[index + 5] ? (
                              <Link
                                key={filteredData[index + 5].uid}
                                href={`/${filteredData[
                                  index + 5
                                ].content_type.toLowerCase()}/${
                                  filteredData[index + 5].slug ||
                                  filteredData[index + 5].uid
                                }`}
                                passHref
                                prefetch={false}
                              >
                                <Flex
                                  align="center"
                                  sx={{
                                    cursor: 'pointer',
                                    marginTop: '-8px'
                                  }}
                                >
                                  <Group
                                    spacing={0}
                                    sx={{ minWidth: '30%' }}
                                    align="baseline"
                                  >
                                    <Text
                                      fw={800}
                                      color="rgba(123,57,182,1)"
                                      size="20px"
                                      sx={{
                                        lineHeight: 0.9,
                                        marginLeft: '4px',
                                        width: '20px',
                                        textAlign: 'left',
                                        ...(index === 4 && {
                                          marginLeft: '-3px'
                                        })
                                      }}
                                    >
                                      {index + 6}
                                    </Text>
                                  </Group>
                                  <Text
                                    color="white"
                                    tt="uppercase"
                                    fw={400}
                                    size={14}
                                    sx={{
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: 'block',
                                      maxWidth: '180px',
                                      marginLeft: '-35px'
                                    }}
                                  >
                                    {filteredData[index + 5].title}
                                  </Text>
                                </Flex>
                              </Link>
                            ) : (
                              <Flex
                                key={`empty-bottom-${index}`}
                                align="center"
                                sx={{ marginTop: '-8px' }}
                              >
                                <Group
                                  spacing={0}
                                  sx={{ minWidth: '30%' }}
                                  align="baseline"
                                >
                                  <Text
                                    fw={800}
                                    color="rgba(123,57,182,1)"
                                    size="20px"
                                    sx={{
                                      lineHeight: 0.9,
                                      marginLeft: '4px',
                                      width: '20px',
                                      textAlign: 'left',
                                      ...(index === 4 && {
                                        marginLeft: '-3px'
                                      })
                                    }}
                                  >
                                    {index + 6}
                                  </Text>
                                </Group>
                                <Text
                                  color="white"
                                  tt="uppercase"
                                  fw={400}
                                  size={14}
                                  sx={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    display: 'block',
                                    maxWidth: '180px',
                                    marginLeft: '-35px'
                                  }}
                                >
                                  -
                                </Text>
                              </Flex>
                            )
                          )}

                        <Button
                          mt="xs"
                          size="xs"
                          fullWidth
                          component={Link}
                          href={`/${locale}/hits/${countryCode}/${
                            platformData.id
                          }-${platform.platform.replace(/\s+/g, '').toLowerCase()}`}
                          sx={{
                            backgroundColor: '#7B39B6',
                            color: 'white',
                            fontSize: '10px',
                            fontWeight: 'bold',
                            height: 'auto',
                            padding: '2px',
                            '&:hover': {
                              backgroundColor: '#6a2ca6'
                            }
                          }}
                        >
                          <Stack spacing={2} align="center">
                            <Text>{t('seeMore')}</Text>
                            <Text>{platform.platform.toUpperCase()}</Text>
                          </Stack>
                        </Button>
                      </Stack>
                    </Card>
                  </Box>
                )
              })}
            </Group>
          </InfiniteScroll>
        </Stack>
      </main>
    </>
  )
}

Home.propTypes = {
  title: PropTypes.string,
  initialPlatformHits: PropTypes.object,
  countryHits: PropTypes.array,
  initialAllPlatforms: PropTypes.array
}

Home.getLayout = function getLayout(page) {
  return (
    <Layout showSearchBar withAutocomplete showHero={true} pageName="Index">
      {page}
    </Layout>
  )
}

export async function getStaticProps({ locale }) {
  const countryCode = locale.split('-')[1]?.toLowerCase()
  const language = locale.split('-')[0]?.toLowerCase()

  try {
    // Aumentamos el límite a 50 plataformas
    const { data: response } = await searchAPI_v2.get(
      `platforms/top?country=${countryCode}&page=1&limit=50`
    )

    if (!response?.data?.length) {
      throw new Error('No platforms found')
    }

    // Filtrar plataformas duplicadas antes de procesar
    const uniquePlatforms = Array.from(
      new Map(
        response.data.map((platform) => [platform.platform, platform])
      ).values()
    )

    // Tomamos solo las primeras 7 plataformas para el procesamiento inicial
    const initialPlatforms = uniquePlatforms.slice(0, 7)

    const platformParams = initialPlatforms.map((platform) => ({
      country: countryCode,
      type: ['movie', 'series'],
      limit: 10,
      platform: platform.id,
      logo: platform.logo,
      language: language
    }))

    const results = await Promise.all(
      platformParams.map(async (params) => {
        const [moviesData, seriesData] = await Promise.all([
          searchAPI_v2.get('content/hits', { params: { ...params, type: 'movie' } }),
          searchAPI_v2.get('content/hits', { params: { ...params, type: 'series' } })
        ])

        const parsedMovies = moviesData.data.data.map((movie) => ({
          title: movie.translation?.title || movie.title,
          poster: movie.poster,
          backdrop: movie.backdrop,
          genre: movie.genres[0] || '',
          year: movie.year,
          uid: movie.uid,
          slug: movie.slug,
          content_type: movie.content_type,
          hits: movie.hits
        }))

        const parsedSeries = seriesData.data.data.map((series) => ({
          title: series.translation?.title || series.title,
          poster: series.poster,
          backdrop: series.backdrop,
          genre: series.genres[0] || '',
          year: series.year,
          uid: series.uid,
          slug: series.slug,
          content_type: series.content_type,
          hits: series.hits
        }))

        // Filtrar duplicados en el contenido de películas y series
        const allContent = [...parsedMovies, ...parsedSeries]
        const uniqueContent = Array.from(
          new Map(
            allContent.map((item) => [`${item.uid}-${item.title}`, item])
          ).values()
        )

        return uniqueContent.sort((a, b) => b.hits - a.hits)
      })
    )

    const hits = initialPlatforms.reduce((acc, platform, index) => {
      acc[platform.platform] = {
        id: platform.id,
        data: results[index] || [],
        logo: platform.logo
      }
      return acc
    }, {})

    const translation = (
      await import(`../locales/${locale.split('-')[0]}/common.json`)
    ).default

    const messages = {
      Genres: translation.Genres,
      Index: translation.Index,
      Navbar: translation.Navbar,
      Footer: translation.Footer,
      Cookies: translation.Cookies
    }

    // Fetch country hits
    const countryHitsParams = {
      country: countryCode,
      limit: 10,
      language: language
    }

    const [moviesData, seriesData] = await Promise.all([
      searchAPI_v2.get('content/hits', {
        params: { ...countryHitsParams, type: 'movie' }
      }),
      searchAPI_v2.get('content/hits', {
        params: { ...countryHitsParams, type: 'series' }
      })
    ])

    const allCountryHits = [...moviesData.data.data, ...seriesData.data.data]
      .sort((a, b) => b.hits - a.hits)
      .map((item) => ({
        title: item.translation?.title || item.title,
        poster: item.poster,
        backdrop: item.backdrop,
        genre: item.genres[0] || '',
        year: item.year,
        uid: item.uid,
        slug: item.slug,
        content_type: item.content_type,
        hits: item.hits
      }))

    // Filtrar duplicados usando Set para uid y title
    const uniqueCountryHits = Array.from(
      new Map(
        allCountryHits.map((item) => [`${item.uid}-${item.title}`, item])
      ).values()
    )

    return {
      props: {
        initialPlatformHits: hits,
        countryHits: uniqueCountryHits,
        initialAllPlatforms: uniquePlatforms,
        messages
      },
      revalidate: 60 * 60 * 24
    }
  } catch (error) {
    return {
      props: {
        error: 'No se pudieron cargar los datos',
        initialAllPlatforms: [],
        initialPlatformHits: {},
        countryHits: [],
        messages: {}
      },
      revalidate: 60 * 60 * 24
    }
  }
}
